<template>
  <v-dialog
    persistent
    @keydown.esc="closeModal()"
    v-model="isVisible"
    content-class="my-custom-dialog"
    :width="dialogWidth"
    :key="isVisible"
  >
    <div
      @mousedown="(evt) => evt.stopPropagation() && evt.preventDefault()"
      ref="scrollContainer"
      style="overflow-y: auto; max-height: 80vh"
    >
      <v-card
        ref="planContactModal"
        :height="$vuetify.breakpoint.smAndDown ? '90vh' : ''"
        :class="{ overflow: $vuetify.breakpoint.smAndDown }"
      >
        <div class="toolbar">
          <span class="">Zaplanuj następny kontakt</span>
          <span>
            <BlackButton
              fab
              small
              icon="times"
              iconMargin=""
              class="close"
              @click.native="closeModal()"
            />
          </span>
        </div>

        <div v-if="!customerId && !userId" class="mx-4 my-2 mb-0">
          <v-autocomplete
            v-model="selectedCustomerId"
            outlined
            :items="customers"
            dense
            label="Kontakt"
            item-text="name"
            item-value="id"
            closable-chips
          >
          </v-autocomplete>

          <YellowButton
            class="mr-4"
            text="Dodaj kontakt"
            icon="plus"
            @click.native="addCustomerModal = true"
            xsmall
          />
        </div>

        <v-card-text class="px-0 py-0">
          <v-tabs color="#fbc501" fixed-tabs v-model="activeTab">
            <v-tab-item key="calendar">
              <v-date-picker
                class="mt-4"
                v-model="dateFrom"
                first-day-of-week="1"
                color="white"
                full-width
                :timezone="`UTC`"
                no-title
              />
            </v-tab-item>
          </v-tabs>
          <div class="time-pickers">
            <div class="time-picker" v-if="dateFrom">
              <span class="time-picker-label">Data rozpoczęcia</span>
              <v-text-field
                class="time"
                v-model="dateFromWithTime"
                type="datetime-local"
                color="#fbc501"
              ></v-text-field>
            </div>

            <div class="time-picker" v-if="dateFrom">
              <span class="time-picker-label">Data zakończenia</span>
              <v-text-field
                class="time"
                v-model="dateToWithTime"
                single-line
                type="datetime-local"
                color="#fbc501"
              ></v-text-field>
            </div>
          </div>
        </v-card-text>

        <v-card-text>
          <div class="business-talk-label">
            <p>Rozmowa biznesowa</p>
            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <font-awesome-icon
                  @click.stop
                  v-on="on"
                  v-bind="props"
                  icon="info-circle"
                  color="#fbc501"
                  size="1x"
                />
              </template>
              Zaznaczając jeden z poniższych checkboxów Twoje dane zostaną
              uwzględnione w raporcie: ROZMOWY BIZNESOWE.
            </v-tooltip>
          </div>
          <v-checkbox
            v-if="customerId || selectedCustomerId || (!customerId && !userId)"
            color="#fbc501"
            style="height: 30px"
            v-model="businessConversationRBp"
            label="pierwsza (RBp)"
          ></v-checkbox>
          <v-checkbox
            v-if="customerId || selectedCustomerId || (!customerId && !userId)"
            color="#fbc501"
            style="height: 30px"
            v-model="businessConversationRBk"
            label="kolejna (RBk)"
          ></v-checkbox>
          <v-checkbox
            v-if="customerId || selectedCustomerId || (!customerId && !userId)"
            color="#fbc501"
            style="height: 30px; margin-bottom: 20px"
            v-model="businessConversationRBf"
            label="finalizacja (RBf)"
          ></v-checkbox>

          <v-text-area-with-validation
            rules="max:500"
            label="Temat"
            v-model="topic"
            outlined
            dense
            :withoutBorderRadius="true"
            :maxLengthInfo="500"
          />

          <v-checkbox
            v-if="customerId || selectedCustomerId || (!customerId && !userId)"
            color="#fbc501"
            style="height: 30px; margin-top: 30px"
            v-model="contactMade"
            label="Kontakt odbył się"
          ></v-checkbox>
          <v-checkbox
            v-if="customerId || selectedCustomerId || (!customerId && !userId)"
            color="#fbc501"
            style="height: 30px"
            v-model="contactNotMade"
            label="Kontakt nie odbył się"
          ></v-checkbox>

          <!-- <v-checkbox
          v-if="(contact && contact.id && contact.googleEventId) || !contact"
          color="#fbc501"
          :label="
            contact && contact.id && contact.googleEventId
              ? 'Zaktualizuj wydarzenie w kalendarzu Google'
              : 'Dodaj wydarzenie do kalendarza Google'
          "
          v-model="addOrUpdateGoogleEvent"
        >
        </v-checkbox> -->

          <YellowButton
            text="Zapisz"
            width="60%"
            @click.native="okHandler()"
            id="okButton"
            class="mt-4"
          />
        </v-card-text>

        <!-- <v-card-text class="calendar-container">
        <v-tabs color="#fbc501" fixed-tabs v-model="activeTab">
          <p class="calendar-label">Data zakończenia kontaktu</p>
          <v-tab-item key="calendar">
            <v-date-picker
              v-model="dateTo"
              first-day-of-week="1"
              color="white"
              full-width
              :timezone="`UTC`"
            />
          </v-tab-item>
        </v-tabs>
        <div class="time-picker">
          <v-text-field
            class="time"
            v-model="timeTo"
            single-line
            type="time"
          ></v-text-field>
        </div>
      </v-card-text> -->
      </v-card>
    </div>

    <customer-modal
      :isEdit="false"
      :isVisible="addCustomerModal"
      @closeModal="addCustomerModal = false"
      @fetch="handleNewContactAdded($event, customerId)"
    />
  </v-dialog>
</template>

<script>
import moment from "moment";
import YellowButton from "../buttons/YellowButton";
import BlackButton from "../buttons/BlackButton";
import VTextAreaWithValidation from "../inputs/VTextAreaWithValidation.vue";
import CustomerModal from "./CustomerModal.vue";

const DEFAULT_DATE = "";
const DEFAULT_TIME = "00:00:00";
const DEFAULT_DATE_FORMAT = "YYYY-MM-DD";
const DEFAULT_DATE_FORMAT_TEXT = "DD/MM/YYYY";
const DEFAULT_TIME_FORMAT = "HH:mm";
const DEFAULT_DIALOG_WIDTH = 340;
export default {
  name: "SetDateTimeFromToModal",
  emits: ["contactsUpdated"],
  components: {
    YellowButton,
    BlackButton,
    VTextAreaWithValidation,
    CustomerModal,
  },
  props: {
    datetimeFrom: {
      type: [Date, String],
      default: null,
    },
    datetimeTo: {
      type: [Date, String],
      default: null,
    },
    disabled: {
      type: Boolean,
    },
    isVisible: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: "",
    },
    dialogWidth: {
      type: Number,
      default: DEFAULT_DIALOG_WIDTH,
    },
    timeFormat: {
      type: String,
      default: DEFAULT_TIME_FORMAT,
    },
    dateFormat: {
      type: String,
      default: DEFAULT_DATE_FORMAT,
    },
    customerId: {
      type: String,
      default: "",
    },
    userId: {
      type: String,
      default: "",
    },
    contact: {
      type: [String, Object],
      default: "",
    },
  },
  data() {
    return {
      display: false,
      activeTab: 0,
      dateFrom: DEFAULT_DATE,
      dateTo: DEFAULT_DATE,
      timeFrom: DEFAULT_TIME,
      timeTo: DEFAULT_TIME,
      contactMade: false,
      topic: "",
      dateFromWithTime: "",
      dateToWithTime: "",
      contactNotMade: false,
      businessConversationRBp: false,
      businessConversationRBk: false,
      businessConversationRBf: false,
      selectedBusinessConversation: "",
      selectedCustomerId: "",
      addCustomerModal: false,
    };
  },
  mounted() {
    this.init();
  },
  computed: {
    customers() {
      const data = this.$store.getters["customers/getCustomersPrimaryData"];
      if (data) {
        return data.map((customer) => {
          return {
            id: customer.id,
            name: customer.fullname,
          };
        });
      } else {
        return [];
      }
    },
    dateTimeFormat() {
      return this.timeFormat + ", " + this.dateFormat;
    },
    dateTimeFormatText() {
      return this.timeFormat + ", " + DEFAULT_DATE_FORMAT_TEXT;
    },
    defaultDateTimeFormat() {
      return DEFAULT_TIME_FORMAT + ", " + DEFAULT_DATE_FORMAT;
    },
    formattedDatetimeFrom() {
      return this.selectedDatetimeFrom
        ? moment(this.selectedDatetimeFrom).format(this.dateTimeFormat)
        : "";
    },
    formattedDatetimeTextFrom() {
      return this.selectedDatetimeFrom
        ? moment(this.selectedDatetimeFrom).format(this.dateTimeFormatText)
        : "";
    },
    selectedDatetimeFrom() {
      if (this.dateFrom && this.timeFrom) {
        let datetimeString = this.dateFrom + " " + this.timeFrom;
        // if (this.timeFrom.length === 5) {
        //     datetimeString += ':00'
        // }
        return moment(datetimeString).toDate();
      } else {
        return null;
      }
    },
    formattedDatetimeTo() {
      return this.selectedDatetimeTo
        ? moment(this.selectedDatetimeTo).format(this.dateTimeFormat)
        : "";
    },
    formattedDatetimeTextTo() {
      return this.selectedDatetimeTo
        ? moment(this.selectedDatetimeTo).format(this.dateTimeFormatText)
        : "";
    },
    selectedDatetimeTo() {
      if (this.dateTo && this.timeTo) {
        let datetimeString = this.dateTo + " " + this.timeTo;
        // if (this.timeFrom.length === 5) {
        //     datetimeString += ':00'
        // }
        return moment(datetimeString).toDate();
      } else {
        return null;
      }
    },

    dateSelected() {
      return !this.dateFrom && this.dateTo;
    },
  },
  methods: {
    async handleNewContactAdded(customerId) {
      await this.fetchCustomersPrimaryList();
      if (customerId) {
        this.selectedCustomerId = customerId;
      }
    },
    async fetchCustomersPrimaryList() {
      await this.$store.dispatch("customers/fetchCustomersPrimaryData");
    },
    async init() {
      if (!this.customerId && !this.userId) {
        this.selectedCustomerId = "";
      }
      if (!this.customerId) {
        await this.fetchCustomersPrimaryList();
        // if (this.customers && this.customers.length > 0) {
        //   this.selectedCustomerId = this.customers[0]?.id;
        // }
      }
      if (this.datetimeFrom) {
        let initDateTime;
        if (this.datetimeFrom instanceof Date) {
          initDateTime = this.datetimeFrom;
        } else if (
          typeof this.datetimeFrom === "string" ||
          this.datetimeFrom instanceof String
        ) {
          initDateTime = moment(this.datetimeFrom).toDate();
        }

        this.dateFrom = moment(initDateTime).format(DEFAULT_DATE_FORMAT);
        this.timeFrom = moment(initDateTime).format(DEFAULT_TIME_FORMAT);
        this.dateFromWithTime = moment(initDateTime).format("YYYY-MM-DDTHH:mm");
      }

      if (this.datetimeTo) {
        let initDateTime;
        if (this.datetimeTo instanceof Date) {
          initDateTime = this.datetimeTo;
        } else if (
          typeof this.datetimeTo === "string" ||
          this.datetimeTo instanceof String
        ) {
          // see https://stackoverflow.com/a/9436948
          initDateTime = moment(this.datetimeTo).toDate();
        }

        this.dateTo = moment(initDateTime).format(DEFAULT_DATE_FORMAT);
        this.timeTo = moment(initDateTime).format(DEFAULT_TIME_FORMAT);
        this.dateToWithTime = moment(initDateTime).format("YYYY-MM-DDTHH:mm");
      }

      this.selectedBusinessConversation = this.contact
        ? this.contact.businessConversation
        : "";
    },
    async okHandler() {
      if (!this.dateFromWithTime || !this.dateToWithTime) {
        this.$store.dispatch(
          "snackbar/setSnackbar",
          {
            color: "red",
            icon: "exclamation-triangle",
            message: "Wybierz datę spotkania",
          },
          { root: true }
        );
        this.$store.dispatch("snackbar/toggleSnackbar", true, { root: true });
        return;
      }

      if (!this.topic || this.topic.length === 0 || this.topic.length > 500) {
        this.$store.dispatch(
          "snackbar/setSnackbar",
          {
            color: "red",
            icon: "exclamation-triangle",
            message:
              "Pole temat jest obowiązkowe. Musi zawierać od 1 do 500 znaków",
          },
          { root: true }
        );
        this.$store.dispatch("snackbar/toggleSnackbar", true, { root: true });
        return;
      }

      if (!this.customerId && !this.userId && !this.selectedCustomerId) {
        this.$store.dispatch(
          "snackbar/setSnackbar",
          {
            color: "red",
            icon: "exclamation-triangle",
            message: "Wybierz kontakt",
          },
          { root: true }
        );
        this.$store.dispatch("snackbar/toggleSnackbar", true, { root: true });
        return;
      }

      const momentDateFrom = moment(this.dateFromWithTime);
      const momentDateTo = moment(this.dateToWithTime);
      if (momentDateTo.isBefore(momentDateFrom)) {
        this.$store.dispatch(
          "snackbar/setSnackbar",
          {
            color: "red",
            icon: "exclamation-triangle",
            message:
              "Niepoprawne daty. Data zakończenie nie może być wcześniej niż data rozpoczęcia.",
          },
          { root: true }
        );
        this.$store.dispatch("snackbar/toggleSnackbar", true, { root: true });
        return;
      }
      if (this.customerId && !this.contact) {
        let object = {
          customerId: this.customerId,
          contactDateFrom: this.dateFromWithTime,
          contactDateTo: this.dateToWithTime,
          topic: this.topic,
          contactMade: this.contactMade,
          contactNotMade: this.contactNotMade,
          businessConversation: this.selectedBusinessConversation,
        };
        await this.$store.dispatch("customers/addContact", object);
        this.$emit("contactAdded");
      }
      if (this.customerId && this.contact) {
        let object = {
          customerId: this.customerId,
          contactId: this.contact.id,
          contactDateFrom: this.dateFromWithTime,
          contactDateTo: this.dateToWithTime,
          topic: this.topic,
          contactMade: this.contactMade,
          contactNotMade: this.contactNotMade,
          businessConversation: this.selectedBusinessConversation,
        };
        await this.$store.dispatch("customers/editContact", object);
        this.$emit("contactsUpdated");
        this.$emit("contactEdited");
      }
      if (this.userId) {
        let object = {
          expiredDate: this.dateToWithTime,
          id: this.userId,
        };
        this.$store.dispatch("user/updateExpiryDate", object);
      }
      if (!this.customerId && !this.userId) {
        let object = {
          customerId: this.selectedCustomerId,
          contactDateFrom: this.dateFromWithTime,
          contactDateTo: this.dateToWithTime,
          topic: this.topic,
          contactMade: this.contactMade,
          contactNotMade: this.contactNotMade,
          businessConversation: this.selectedBusinessConversation,
        };
        await this.$store.dispatch("customers/addContact", object);
        this.$emit("contactsUpdated");
      }

      this.closeModal();
    },
    closeModal() {
      this.resetPicker();
      this.topic = "";
      this.contactMade = false;
      this.contactNotMade = false;
      this.businessConversationRBf = false;
      this.businessConversationRBk = false;
      this.businessConversationRBp = false;
      this.$emit("closeModal");
    },
    resetPicker() {
      this.dateFrom = DEFAULT_DATE;
      this.timeFrom = DEFAULT_TIME;
      this.dateTo = DEFAULT_DATE;
      this.timeTo = DEFAULT_TIME;
      this.display = false;
      this.activeTab = 0;
      if (this.$refs.timer) {
        this.$refs.timer.selectingHour = true;
      }
    },
    showTimePicker() {
      this.activeTab = 1;
    },
  },
  watch: {
    isVisible(value) {
      if (value) {
        this.$nextTick(() => {
          setTimeout(() => {
            this.$refs.scrollContainer.scrollTop = 0;
          }, 0);
        });
      }
    },

    businessConversationRBp() {
      if (this.businessConversationRBp) {
        this.businessConversationRBk = false;
        this.businessConversationRBf = false;
        this.selectedBusinessConversation = "pierwsza (RBp)";
        if (
          !this.topic ||
          this.topic === "Rozmowa biznesowa kolejna" ||
          this.topic === "Finalizacja"
        ) {
          this.topic = "Rozmowa biznesowa pierwsza";
        }
      }
    },
    businessConversationRBk() {
      if (this.businessConversationRBk) {
        this.businessConversationRBp = false;
        this.businessConversationRBf = false;
        this.selectedBusinessConversation = "kolejna (RBk)";
        if (
          !this.topic ||
          this.topic === "Rozmowa biznesowa pierwsza" ||
          this.topic === "Finalizacja"
        ) {
          this.topic = "Rozmowa biznesowa kolejna";
        }
      }
    },
    businessConversationRBf() {
      if (this.businessConversationRBf) {
        this.businessConversationRBp = false;
        this.businessConversationRBk = false;
        this.selectedBusinessConversation = "finalizacja (RBf)";
        if (
          !this.topic ||
          this.topic === "Rozmowa biznesowa pierwsza" ||
          this.topic === "Rozmowa biznesowa kolejna"
        ) {
          this.topic = "Finalizacja";
        }
      }
    },

    contactMade() {
      if (this.contactMade) {
        this.contactNotMade = false;
      }
    },
    contactNotMade() {
      if (this.contactNotMade) {
        this.contactMade = false;
      }
    },
    datetimeFrom: function () {
      this.init();
    },
    datetimeTo: function () {
      this.init();
    },
    dateFrom: function () {
      const selectedHours = this.dateFromWithTime
        ? moment(this.dateFromWithTime).get("hours")
        : moment().get("hours") + 1;
      const selectedMinutes = this.dateFromWithTime
        ? moment(this.dateFromWithTime).get("minutes")
        : 0;
      const newDateToSet = moment(this.dateFrom)
        .set("hours", selectedHours)
        .set("minutes", selectedMinutes)
        .format("YYYY-MM-DDTHH:mm");
      this.dateFromWithTime = newDateToSet;

      if (!this.datetimeTo) {
        this.dateToWithTime = moment(newDateToSet)
          .add(1, "hour")
          .format("YYYY-MM-DDTHH:mm");
      }

      // setTimeout(() => {
      //   const okButton = document.getElementById("okButton");
      //   if (okButton) {
      //     okButton.scrollIntoView({ block: "start" });
      //   }
      // }, 10);
    },
    contact() {
      if (this.contact) {
        this.contactMade = this.contact.done;
        this.contactNotMade = this.contact.notDone;
        this.topic = this.contact.topic;
        if (this.contact.businessConversation === "pierwsza (RBp)") {
          this.businessConversationRBp = true;
          this.businessConversationRBk = false;
          this.businessConversationRBf = false;
        } else if (this.contact.businessConversation === "kolejna (RBk)") {
          this.businessConversationRBp = false;
          this.businessConversationRBk = true;
          this.businessConversationRBf = false;
        } else if (this.contact.businessConversation === "finalizacja (RBf)") {
          this.businessConversationRBp = false;
          this.businessConversationRBk = false;
          this.businessConversationRBf = true;
        } else {
          this.businessConversationRBp = false;
          this.businessConversationRBk = false;
          this.businessConversationRBf = false;
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.calendars-container {
  display: flex;
  width: 100%;
}
.calendar-container {
  border: 1px solid rgb(191, 184, 184);
  margin: 2rem;
}
.calendar-label {
  font-size: 20px;
  margin-top: 1rem;
  font-weight: 500;
}
.time-picker-label {
  position: absolute;
  left: 1rem;
  padding: 0 0.5rem;
}
.time-picker {
  position: relative;
  display: flex;
  justify-content: center;
  padding: 0 1.5rem;
  margin: 0;
}
.v-picker.v-card.v-picker--date.v-picker--full-width {
  height: 392px;
}
.toolbar {
  width: 100%;
  margin: 0;
  padding: 1rem 1rem;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
  background: #fbc501;
  font-size: 0.9rem;
}
.business-talk-label {
  display: flex;
  justify-content: flex-start;
}
.business-talk-label svg {
  margin-top: 4px;
  margin-left: 5px;
}

.v-card > :first-child:not(.v-btn):not(.v-chip),
.v-card > .v-card__progress + :not(.v-btn):not(.v-chip),
.v-picker__title {
  border-radius: 0 !important;
}
::v-deep .v-picker__title {
  color: black !important;
}
::v-deep .v-time-picker-title {
  color: black !important;
}
::v-deep .v-time-picker-clock__item {
  color: black !important;
}
::v-deep .v-btn--rounded {
  color: black !important;
}
::v-deep .white--text {
  color: black !important;
}

.overflow {
  overflow: scroll;
}
.calendars-title {
  background: #fbc501;
  color: #000;
  height: 75px;
  display: flex;
  align-items: center;
  padding: 0 1rem;
  font-weight: 500;
  justify-content: space-between;
}

::v-deep input[type="datetime-local"]::-webkit-calendar-picker-indicator {
  display: none !important;
  -webkit-appearance: none !important;
}
.v-input--selection-controls {
  margin-top: 0;
  padding: 0;
}

::v-deep .v-tabs-bar {
  height: 1px;
}
.time-pickers {
  margin-top: -100px;
}

@import "~vuetify/src/styles/settings/_variables";
</style>
